<template>
  <div>
    <TableUpdateRequest
      ref="tableUpdateRequest"
      :allfilters="DataFilters"
      :fields="toRevisionFields"
      :params="sendParams"
    >
      <template #lead_name="data">
        <span class="d-flex align-items-center" style="gap: 4px">
          <router-link
            v-if="moduleId != 22"
            :to="accessElementTable.getToParams(data.item.account_id)"
            target="_blank"
          >
            {{ data.item.client_name }}
          </router-link>
          <b-badge
            class="font10 ml-1"
            :variant="
              data.item.name_type_client == 'DIGITAL' ? 'info' : 'primary'
            "
            v-if="[6, 20, 21, 22].includes(data.item.module_id)"
          >
            {{ data.item.name_type_client }}
          </b-badge>
          <router-link
            v-if="getRouterLink(data.item.account_id).validModuleId"
            :to="getRouterLink(data.item.account_id).to"
          >
            <icon-status-account :status="data.item.status_account" />
            {{ data.item.client_name }}
          </router-link>
        </span>
        <status-account :account="data.item" :text="true" />
        <div class="d-flex align-items-center">
          <span class="d-block">
            {{ data.item.client_account }}
          </span>
        </div>
      </template>
      <template #last_score_date="data">
        {{ data.item.last_score_date | myGlobalDay }}
      </template>
      <template #user_created="data">
        <b-col>
          <b-row
            ><span> {{ data.item.user_update }} </span></b-row
          >
          <b-row
            ><span>{{ data.item.updated_at | myGlobalWithHour }}</span></b-row
          >
        </b-col>
      </template>
      <template #step="{ item }">
        <div class="d-flex flex-column align-items-center">
          <amg-icon
            @click="openModalSteps(item)"
            size="25"
            :icon="steps.icons[item.current_step - 1]"
            class="text-primary cursor-pointer"
          />
          <b-badge variant="dark">{{
            steps.step[item.current_step - 1]
          }}</b-badge>
        </div>
      </template>
      <template #recommended_services="{ item }">
        <b-badge v-if="!displayedServices(item)" variant="light-danger">
          <div class="d-flex justify-content-center align-items-center gap-5">
            <feather-icon icon="SlashIcon" />
            <span class="lh-0"> None </span>
          </div>
        </b-badge>
        <b-badge v-else variant="light-info">
          <div class="d-flex justify-content-center align-items-center gap-5">
            <feather-icon icon="GridIcon" />
            <span class="lh-0">
              {{ displayedServices(item).length }} services
            </span>
          </div>
        </b-badge>
      </template>
      <template #read="data">
        <center>
          <b-badge
            pill
            :variant="data.item.read ? 'light-danger' : 'light-success'"
            :class="data.item.read ? 'text-danger' : 'text-success'"
          >
            {{ data.item.read | statusDisputeResultsRead }}
          </b-badge>
        </center>
      </template>
      <template #type="data">
        <b-badge
          class="w-100 text-center d-flex justify-content-center"
          :variant="data.item.type == 1 ? 'light-success' : 'light-warning'"
        >
          {{ data.item.type == 1 ? "LETTER" : "RECOVERY" }}
        </b-badge>
      </template>
      <template #update_file="{ item }">
        <template v-for="(data, index) in JSON.parse(item.files)">
          <a
            :key="index"
            :href="data.url"
            target="_blank"
            v-b-tooltip.hover.top="data.file_name"
          >
            <amg-icon
              :key="index"
              icon="FilePdfIcon"
              style="height: 18px; width: 18px; margin-bottom: 3px"
              class="cursor-pointer mt-0 mr-0 bigger text-danger text-center"
          /></a>
        </template>
      </template>
      <template #actions="{ item }">
        <div class="d-flex justify-content-center align-items-center">
          <feather-icon
            class="text-primary cursor-pointer mr-1"
            v-b-tooltip.hover.right="'Completed Update Request'"
            @click="openActionModal(item)"
            size="20"
            icon="EyeIcon"
          />
        </div>
      </template>
    </TableUpdateRequest>
  </div>
</template>
<script>
import { toRevisionFields } from "@/views/commons/components/dispute-results/views/data/fields.data";
import DataFilters from "@/views/specialist-digital/views/updates/data/filters.data.js";
import TableUpdateRequest from "@/views/commons/components/dispute-results/views/components/TableUpdateRequest.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import UpdateService from "@/views/specialist-digital/views/updates/services/update.service.js";

import { mapGetters } from "vuex";

export default {
  name: "TableToRevision",
  components: {
    TableUpdateRequest,
    StatusAccount,
  },
  data() {
    return {
      toRevisionFields,
      DataFilters,
      params: {},
      users: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isToRevisionTab() {
      const { routeToRevision } = this.$route.matched[1].meta;
      return routeToRevision == this.$route.name;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    sendParams() {
      return {
        orderby: "updated_at",
        status: 3,
        specialist_id: this.isCreditAnalyst
          ? this.currentUser.user_id
          : this.getFilterByKey("specialist").model,
      };
    },
    isCreditAnalyst() {
      return this.currentUser.role_id == 22;
    },
    steps() {
      return this.$refs.tableUpdateRequest.getSteps();
    },
    isSpecialist() {
      return this.moduleId === 28;
    },
    isAnalystLeader() {
      return this.currentUser.role_id == 21;
    },
    accessElementTable() {
      return this.$refs.tableUpdateRequest;
    },
  },
  methods: {
    async getCreditAnalyst() {
      let result = [];
      result = await UpdateService.getCreditAnalyst();
      this.users = result.data;
      let newList = this.users.map(
        (s) => (
          { label: "All", value: 0 }, { label: s.specialist, value: s.id }
        )
      );
      newList.splice(0, 0, { label: "All", value: 0 }); //add position 0
      this.DataFilters[5].options = newList;
    },
    start() {
      this.$nextTick(async () => {
        let table = this.$refs.tableUpdateRequest;
        table.filterColumn(
          "actions",
          this.isSpecialist &&
            (this.isSupervisor ||
              this.isChief ||
              this.isCeo ||
              this.isAnalystLeader)
        );
        await this.getCreditAnalyst();
      });
    },
    getFilterByKey(key) {
      return this.DataFilters.find((filter) => filter.key === `${key}`);
    },
    async openModalSteps(item) {
      this.$refs.tableUpdateRequest.openModalSteps(item);
    },
    displayedServices(item) {
      if (item.recommended_services) {
        const services = JSON.parse(item.recommended_services);
        return services;
      }
    },
    async openActionModal(item) {
      try {
        this.addPreloader();
        const { data } = await UpdateService.getCompletedUrInfo({
          id: item.id,
          score_id: item.score_id,
        });
        let dataUpdate = item;
        dataUpdate.rounds = data.rounds;
        dataUpdate.creditUtilized = data.creditUtilized;
        dataUpdate.deletedAccounts = data.deletedAccounts;
        this.removePreloader();
        this.$refs.tableUpdateRequest.openActionModal({
          dataClient: dataUpdate,
          revision: this.isToRevisionTab,
          section: "UPDATE",
        });
      } catch (ex) {
        this.removePreloader();
        console.log(ex);
      }
    },
    getRouterLink(client_account_id) {
      return this.accessElementTable.getRouterLink(client_account_id);
    },
  },
  mounted() {
    this.start();
  },
};
</script>
