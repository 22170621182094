var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TableUpdateRequest',{ref:"tableUpdateRequest",attrs:{"allfilters":_vm.DataFilters,"fields":_vm.toRevisionFields,"params":_vm.sendParams},scopedSlots:_vm._u([{key:"lead_name",fn:function(data){return [_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"4px"}},[(_vm.moduleId != 22)?_c('router-link',{attrs:{"to":_vm.accessElementTable.getToParams(data.item.account_id),"target":"_blank"}},[_vm._v(" "+_vm._s(data.item.client_name)+" ")]):_vm._e(),([6, 20, 21, 22].includes(data.item.module_id))?_c('b-badge',{staticClass:"font10 ml-1",attrs:{"variant":data.item.name_type_client == 'DIGITAL' ? 'info' : 'primary'}},[_vm._v(" "+_vm._s(data.item.name_type_client)+" ")]):_vm._e(),(_vm.getRouterLink(data.item.account_id).validModuleId)?_c('router-link',{attrs:{"to":_vm.getRouterLink(data.item.account_id).to}},[_c('icon-status-account',{attrs:{"status":data.item.status_account}}),_vm._v(" "+_vm._s(data.item.client_name)+" ")],1):_vm._e()],1),_c('status-account',{attrs:{"account":data.item,"text":true}}),_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(data.item.client_account)+" ")])])]}},{key:"last_score_date",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(data.item.last_score_date))+" ")]}},{key:"user_created",fn:function(data){return [_c('b-col',[_c('b-row',[_c('span',[_vm._v(" "+_vm._s(data.item.user_update)+" ")])]),_c('b-row',[_c('span',[_vm._v(_vm._s(_vm._f("myGlobalWithHour")(data.item.updated_at)))])])],1)]}},{key:"step",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('amg-icon',{staticClass:"text-primary cursor-pointer",attrs:{"size":"25","icon":_vm.steps.icons[item.current_step - 1]},on:{"click":function($event){return _vm.openModalSteps(item)}}}),_c('b-badge',{attrs:{"variant":"dark"}},[_vm._v(_vm._s(_vm.steps.step[item.current_step - 1]))])],1)]}},{key:"recommended_services",fn:function(ref){
var item = ref.item;
return [(!_vm.displayedServices(item))?_c('b-badge',{attrs:{"variant":"light-danger"}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center gap-5"},[_c('feather-icon',{attrs:{"icon":"SlashIcon"}}),_c('span',{staticClass:"lh-0"},[_vm._v(" None ")])],1)]):_c('b-badge',{attrs:{"variant":"light-info"}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center gap-5"},[_c('feather-icon',{attrs:{"icon":"GridIcon"}}),_c('span',{staticClass:"lh-0"},[_vm._v(" "+_vm._s(_vm.displayedServices(item).length)+" services ")])],1)])]}},{key:"read",fn:function(data){return [_c('center',[_c('b-badge',{class:data.item.read ? 'text-danger' : 'text-success',attrs:{"pill":"","variant":data.item.read ? 'light-danger' : 'light-success'}},[_vm._v(" "+_vm._s(_vm._f("statusDisputeResultsRead")(data.item.read))+" ")])],1)]}},{key:"type",fn:function(data){return [_c('b-badge',{staticClass:"w-100 text-center d-flex justify-content-center",attrs:{"variant":data.item.type == 1 ? 'light-success' : 'light-warning'}},[_vm._v(" "+_vm._s(data.item.type == 1 ? "LETTER" : "RECOVERY")+" ")])]}},{key:"update_file",fn:function(ref){
var item = ref.item;
return [_vm._l((JSON.parse(item.files)),function(data,index){return [_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(data.file_name),expression:"data.file_name",modifiers:{"hover":true,"top":true}}],key:index,attrs:{"href":data.url,"target":"_blank"}},[_c('amg-icon',{key:index,staticClass:"cursor-pointer mt-0 mr-0 bigger text-danger text-center",staticStyle:{"height":"18px","width":"18px","margin-bottom":"3px"},attrs:{"icon":"FilePdfIcon"}})],1)]})]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('Completed Update Request'),expression:"'Completed Update Request'",modifiers:{"hover":true,"right":true}}],staticClass:"text-primary cursor-pointer mr-1",attrs:{"size":"20","icon":"EyeIcon"},on:{"click":function($event){return _vm.openActionModal(item)}}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }