<template>
  <b-container fluid class="p-0">
    <filter-slot
      class="border-3 border-table-radius p-0 m-0"
      :class="
        currentUser.modul_id == 5 ? 'border-top-info' : 'border-top-primary'
      "
      :filter="allfilters"
      :totalRows="totalRows"
      :paginate="paginate"
      :startPage="startPage"
      :toPage="toPage"
      :filterPrincipal="filters.filterPrincipal"
      @reload="$refs['disputeResultsList'].refresh()"
      @onChangeCurrentPage="onChangeCurrentPage"
      @reset-all-filters="refreshTable"
    >
      <template #table>
        <b-table
          class="m-0 p-0 w-100"
          ref="disputeResultsList"
          :items="dataProvider"
          :fields="visibleFields"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
          :sort-direction="order"
          @sort-changed="changeStatusOrder"
          show-empty
          :sticky-header="'70vh'"
          responsive
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(lead_name)="data">
            <span class="d-flex align-items-center" style="gap: 4px">
              <router-link
                v-if="moduleId != 22"
                :to="{
                  name:
                    moduleId == 5
                      ? 'debt-solution-dashboard'
                      : moduleId == 7
                      ? 'boost-credit-dashboard'
                      : moduleId == 6
                      ? 'credit-experts-dashboard'
                      : moduleId == 20
                      ? 'connection-clients-account'
                      : moduleId == 28
                      ? 'specialists-client-dashboard'
                      : null,
                  params: { idClient: `${data.item.account_id}` },
                }"
                target="_blank"
              >
                {{ data.item.client_name }}
              </router-link>
              <b-badge
                class="font10 ml-1"
                :variant="
                  data.item.name_type_client == 'DIGITAL' ? 'info' : 'primary'
                "
                v-if="[6, 20, 21, 22].includes(data.item.module_id)"
              >
                {{ data.item.name_type_client }}
              </b-badge>
              <router-link
                v-if="
                  moduleId == 22 &&
                  (currentUser.role_id === 1 ||
                    currentUser.role_id === 17 ||
                    currentUser.role_id === 2 ||
                    currentUser.role_id === 14)
                "
                :to="{
                  name: 'ce-customer-service-dashboard',
                  params: {
                    idClient: data.item.account_id,
                    hasAccess: true, // is alert
                    typeAccess: 6, // Welcome,
                    wayAccess: 4, // enter by modal
                  },
                }"
              >
                <icon-status-account :status="data.item.status_account" />
                {{ data.item.client_name }}
              </router-link>
            </span>
            <status-account :account="data.item" :text="true" />
            <div class="d-flex align-items-center">
              <span class="d-block">
                {{ data.item.client_account }}
              </span>
            </div>
          </template>
          <template #cell(program)="data">
            <center>
              <span>{{ data.item.program }}</span>
            </center>
          </template>
          <template #cell(title)="data">
            <div
              class="text-primary cursor-pointer font-weight-bolder"
              @click="openModalAddRound(data.item)"
            >
              {{
                [1, 2].includes(data.item.type_round) ? "FIRST ROUND" : "-"
              }}
            </div>
          </template>
          <template #cell(user_created)="data">
            <b-col>
              <b-row
                ><span> {{ data.item.user_update }} </span></b-row
              >
              <b-row
                ><span>{{
                  data.item.updated_at | myGlobalWithHour
                }}</span></b-row
              >
            </b-col>
          </template>

          <template #cell(step)="{ item }">
            <div class="d-flex flex-column align-items-center">
              <amg-icon
                @click="openModalSteps(item)"
                size="25"
                :icon="iconsSteps[item.current_step - 1]"
                class="text-primary cursor-pointer"
              />
              <b-badge variant="dark">{{
                steps[item.current_step - 1]
              }}</b-badge>
            </div>
          </template>
          <template #cell(userrecommendation)="data">
            <b-col>
              <b-row
                ><span> {{ data.item.userrecommendation }} </span></b-row
              >
              <b-row
                ><span>{{
                  data.item.created_recommendation | myGlobalWithHour
                }}</span></b-row
              >
            </b-col>
          </template>
          <template #cell(recommended_services)="{ item }">
            <b-badge v-if="!displayedServices(item)" variant="light-danger">
              <div
                class="d-flex justify-content-center align-items-center gap-5"
              >
                <feather-icon icon="SlashIcon" />
                <span class="lh-0"> None </span>
              </div>
            </b-badge>
            <b-badge v-else variant="light-info">
              <div
                class="d-flex justify-content-center align-items-center gap-5"
              >
                <feather-icon icon="GridIcon" />
                <span class="lh-0">
                  {{ displayedServices(item).length }} services
                </span>
              </div>
            </b-badge>
          </template>
          <template #cell(status)="data">
            <center>
              <b-badge
                pill
                :variant="
                  data.item.status_dispute ? 'light-success' : 'light-warning'
                "
                :class="
                  data.item.status_dispute ? 'text-success' : 'text-warning'
                "
                class="w-75"
              >
                {{ data.item.status }}
              </b-badge>
            </center>
          </template>

          <template #cell(last_score_date)="data">
            {{ data.item.last_score_date | myGlobalDay }}
          </template>
          <template #cell(read)="data">
            <center>
              <b-badge
                pill
                :variant="data.item.read ? 'light-danger' : 'light-success'"
                :class="data.item.read ? 'text-danger' : 'text-success'"
              >
                {{ data.item.read | statusDisputeResultsRead }}
              </b-badge>
            </center>
          </template>
          <template #cell(tracking)="{ item }">
            <div>
              <feather-icon
                class="text-info cursor-pointer"
                v-b-tooltip.hover.right="'Tracking'"
                @click="openUrModalTracking(item)"
                size="20"
                icon="ListIcon"
              />
            </div>
          </template>
          <template #cell(request)="{ item }">
            <center>
              <tabler-icon
                icon="FoldersIcon"
                size="30"
                style="color: var(--warning)"
                class="cursor-pointer"
                @click="openModalRequest(item)"
              />
            </center>
          </template>
          <template #cell(type)="data">
            <b-badge
              class="w-100 text-center d-flex justify-content-center"
              :variant="data.item.type == 1 ? 'light-success' : 'light-warning'"
            >
              {{ data.item.type == 1 ? "LETTER" : "RECOVERY" }}
            </b-badge>
          </template>
          <template #cell(update_file)="{ item }">
            <template v-for="(data, index) in JSON.parse(item.files)">
              <a
                :key="index"
                :href="data.url"
                target="_blank"
                v-b-tooltip.hover.top="data.file_name"
              >
                <amg-icon
                  :key="index"
                  icon="FilePdfIcon"
                  style="height: 18px; width: 18px; margin-bottom: 3px"
                  class="cursor-pointer mt-0 mr-0 bigger text-danger text-center"
              /></a>
            </template>
          </template>
          <template #cell(actions)="{ item }">
            <div class="d-flex justify-content-center align-items-center">
              <feather-icon
                class="text-primary cursor-pointer mr-1"
                v-b-tooltip.hover.right="'Completed Update Request'"
                @click="openActionModal(item)"
                size="20"
                icon="EyeIcon"
              />
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <modal-request v-if="activeModalRequest" @closeMe="closeModalRequest" />
    <ActionDoneReturnModal
      v-if="showModalAction"
      :dataClient="dataUpdate"
      :revision="revision"
      :section="'UPDATE'"
      @close="showModalAction = false"
      @reload="refreshTable"
    />
    <add-round-modal
      v-if="modalAddRoundLetterState"
      :data-round="itemClient"
      :validate-modal="validateModal"
      :show-all-letters="false"
      @close="modalAddRoundLetterState = false"
    />
    <RequestUpdateTracking
      v-if="showUrModalTracking"
      :dataUr="dataUr"
      @close="showUrModalTracking = false"
    />
    <StepsCreditReport
      v-if="showModalSteps"
      @close="showModalSteps = false"
      :rowData="rowData"
      @reload="refreshTable"
    />
  </b-container>
</template>
<script>
//components
import AddRoundModal from "@/views/specialist-digital/views/clients/components/AddRoundModal.vue";
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import ModalRequest from "./ModalRequest.vue";
import ActionDoneReturnModal from "@/views/commons/components/request-workplan/views/components/modal/ActionDoneReturnModal.vue";
import RequestUpdateTracking from "@/views/commons/components/dispute-results/views/components/modal/RequestUpdateTracking.vue";
import StepsCreditReport from "@/views/commons/components/dispute-results/views/components/StepsCreditReport.vue";

//vuex
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
//data
import { completedFilters } from "../data/filters.data";
import { toRevisionFields } from "../data/fields.data";
import DataFilters from "@/views/specialist-digital/views/updates/data/filters.data.js";
//services
import DisputeResultsService from "../../services/dispute-results.service";
import UpdateService from "@/views/specialist-digital/views/updates/services/update.service.js";

import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

export default {
  created() {
    this.start();
  },
  components: {
    FilterSlot,
    ModalRequest,
    AddRoundModal,
    ActionDoneReturnModal,
    RequestUpdateTracking,
    StatusAccount,
    StepsCreditReport,
  },
  data() {
    return {
      toRevisionFields,
      isBusy: false,
      filters: {},
      activeModalRequest: false,
      order: "asc",
      allfilters: DataFilters,
      modalEditState: false,
      itemClient: {},
      validateModal: 3,
      modalAddRoundLetterState: false,
      showModalAction: false,
      dataUpdate: null,
      revision: false,
      showUrModalTracking: false,
      dataUr: null,
      showModalSteps: false,
      iconsSteps: ["UpdatedIcon", "DisputeIcon", "RecommendIcon", "CheckIcon"],
      steps: ["UPDATE ITEMS", "DISPUTE", "RECOMMEND", "TO REVIEW"],
      rowData: [],
    };
  },
  methods: {
    refreshTable() {
      this.$refs.disputeResultsList.refresh();
    },
    changeStatusOrder() {
      this.order = this.order === "asc" ? "desc" : "asc";
    },
    async getDisputeCounter() {
      const params = {
        read: 1,
        program_id: this.gProgram(this.moduleId).value,
        is_digital: this.isDigital,
      };
      const data = await DisputeResultsService.getNotificationsDispute(params);
      if (data.length > 0) {
        const payload = {
          routeName: counterRoute,
          tag: data[0].count,
        };
        this.$store.dispatch(
          "BoostCreditDisputeResult/A_SET_COUNTER_DISPUTE",
          data[0].count
        );
        this.$store.commit(
          "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
          payload
        );
      }
    },
    async dataProvider() {
      await this.fillCompletedData({
        advisorid: null,
        from: this.getFilterByKey("from").model,
        name_text: this.filters.filterPrincipal.model,
        order: this.order,
        orderby: "updated_at",
        program: this.gProgram(this.moduleId).value,
        perPage: this.paginate.perPage,
        page: this.paginate.currentPage,
        roleid: this.currentUser.role_id,
        status: 3,
        to: this.getFilterByKey("to").model,
        userid: this.currentUser.user_id,
        typee: this.getFilterByKey("status").model,
        f_specialist: null,
        is_digital: this.isDigital,
        is_deleted: 0,
        module_id: this.moduleId,
        specialist_id: this.isCreditAnalyst
          ? this.currentUser.user_id
          : this.getFilterByKey("specialist").model,
      });
      return this.gCompletedItems;
    },
    getFilterByKey(key) {
      return this.allfilters.find((filter) => filter.key === `${key}`);
    },
    async getSpecialist() {
      let result = [];
      const params = {
        module_id: 11,
        user_id: this.currentUser.user_id,
        role_id: this.currentUser.role_id,
      };
      result = await UpdateService.getUsersProgramsspNcr(params);
      this.users = result.data;
      let newList = this.users.map(
        (s) => ({ label: "All", value: 0 }, { label: s.user_name, value: s.id })
      );
      newList.splice(0, 0, { label: "All", value: 0 }); //add position 0
      this.getFilterByKey("specialist").options = newList;
    },
    start() {
      this.filters = completedFilters;
      this.filterColumn(
        "actions",
        this.isSpecialist &&

          (this.isSupervisor ||
            this.isChief ||
            this.isCeo ||
            this.isAnalystLeader)
      );
      // this.getSpecialist();
      this.getCreditAnalyst();
    },
    onChangeCurrentPage(e) {
      this.paginate.currentPage = e;
    },
    async openModalRequest(row) {
      this.addPreloader();
      this.M_SET_CLIENT({
        name: row.client_name,
        id: row.id,
        account: row.account,
        account_id: row.account_id,
        meEmail: row.id_credential,
      });
      this.M_SET_MODAL_DATA({
        request: row.type,
        observation: row.observation,
        recomendation: row.recommendation,
        userRecommendation: row.userrecommendation,
        dateRecommendation: row.created_recommendation,
        comments: [
          row.commentary1,
          row.commentary2,
          row.commentary3,
          row.commentary4,
          row.commentary5,
        ],
      });
      await this.fillDataTableModal({ id: row.id });
      if (row.type === 2)
        await this.setEmailModal({ client_id: row.account_id });
      if (row.read === 1) {
        await DisputeResultsService.updateReadDisputeResults({
          iddispute: row.id,
        });
        this.getDisputeCounter();

        this.refreshTable();
      }

      this.activeModalRequest = true;
    },
    closeModalRequest() {
      this.activeModalRequest = false;
    },
    displayedServices(item) {
      if (item.recommended_services) {
        const services = JSON.parse(item.recommended_services);
        return services;
      }
    },

    toggleShowAll(item) {
      item.showAll = !item.showAll;
    },
    shouldShowToggle(item) {
      if (item.recommended_services) {
        const services = JSON.parse(item.recommended_services);
        return services.length > 2;
      }
    },

    ...mapMutations({
      M_SET_CLIENT: "DebtSolutionDisputeResult/M_SET_CLIENT",
      M_SET_MODAL_DATA: "DebtSolutionDisputeResult/M_SET_MODAL_DATA",
      M_CHANGE_STATUS_ORDER: "DebtSolutionDisputeResult/M_CHANGE_STATUS_ORDER",
    }),
    ...mapActions({
      fillCompletedData: "DebtSolutionDisputeResult/fillCompletedData",
      fillDataTableModal: "DebtSolutionDisputeResult/fillDataTableModal",
      setEmailModal: "DebtSolutionDisputeResult/setEmailModal",
    }),
    openModalAddRound(data) {
      this.itemClient = {
        id: data.ncr_letters_id,
        idAccount: data.client_account_id,
        account: data.client_account,
        clientName: data.client_name,
        clientData: {
          dob: data.dob,
          address: data.address,
          ssn: data.ssn,
          itin: data.itin,
          other: data.other,
          origin_country: data.origin_country,
        },
        isDirect: {
          id: data.letter_id,
          title: data.title,
          format: data.format,
        },
      };
      this.modalAddRoundLetterState = true;
    },
    async getCreditAnalyst() {
      // result = await UpdateService.getUsersProgramsspNcr(params); Service Old
      let result = [];
      result = await UpdateService.getCreditAnalyst();
      this.users = result.data;
      let newList = this.users.map(
        (s) => (
          { label: "All", value: 0 }, { label: s.specialist, value: s.id }
        )
      );
      newList.splice(0, 0, { label: "All", value: 0 }); //add position 0
      this.allfilters[3].options = newList;
    },
    async openActionModal(item) {
      try {
        this.addPreloader();
        const { data } = await UpdateService.getCompletedUrInfo({
          id: item.id,
          score_id: item.score_id,
        });

        this.showModalAction = true;
        this.dataUpdate = item;
        this.dataUpdate.rounds = data.rounds;
        this.dataUpdate.creditUtilized = data.creditUtilized;
        this.dataUpdate.deletedAccounts = data.deletedAccounts;
        this.revision = this.isToRevisionTab;
      } catch (error) {
      } finally {
        this.removePreloader();
      }
    },
    openUrModalTracking(item) {
      this.showUrModalTracking = true;
      this.dataUr = item;
    },
    filterColumn(key, bool) {
      let field = this.toRevisionFields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },
    async openModalSteps(item) {
      try {
        this.showModalSteps = true;
        this.rowData = item;
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },
  },
  computed: {
    counterRoute() {
      let route =
        this.moduleId == 7
          ? "boost-credit-dispute-results"
          : this.moduleId == 6
          ? "credit-experts-dispute-results"
          : this.moduleId == 22
          ? "customer-service-dispute-results"
          : "debt-solution-dispute-results";
      return route;
    },
    isDigital() {
      return this.$route.meta.is_digital;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isSpecialist() {
      return this.moduleId === 28;
    },
    ...mapState({}),
    ...mapGetters({
      gCompletedItems: "DebtSolutionDisputeResult/gCompletedItems",
      gProgram: "DebtSolutionDisputeResult/gProgram",
      currentUser: "auth/currentUser",
      totalRows: "DebtSolutionDisputeResult/gTotalRows",
      paginate: "DebtSolutionDisputeResult/gPaginate",
      startPage: "DebtSolutionDisputeResult/gStartPage",
      toPage: "DebtSolutionDisputeResult/gToPage",
      gOrder: "DebtSolutionDisputeResult/gOrder",
    }),
    isToRevisionTab() {
      const { routeToRevision } = this.$route.matched[1].meta;
      return routeToRevision == this.$route.name;
    },
    visibleFields() {
      return this.toRevisionFields.filter((field) => field.visible);
    },
    isAnalystLeader() {
      return this.currentUser.role_id == 21;
    },
  },
};
</script>
