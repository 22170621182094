<template>
  <div>
    <TableUpdateRequest
      ref="tableUpdateRequest"
      :allfilters="DataFilters"
      :fields="completedFields"
      :params="sendParams"
      order="desc"
    >
      <template #lead_name="data">
        <span class="d-flex align-items-center" style="gap: 4px">
          <router-link
            v-if="moduleId != 22"
            :to="accessElementTable.getToParams(`${data.item.account_id}`)"
            target="_blank"
          >
            {{ data.item.client_name }}
          </router-link>

          <router-link
            v-if="getRouterLink(data.item.account_id).validModuleId"
            :to="getRouterLink(data.item.account_id).to"
          >
            {{ data.item.client_name }}
          </router-link>
          <b-badge
            class="font10 ml-1"
            :variant="
              data.item.name_type_client == 'DIGITAL' ? 'info' : 'primary'
            "
            v-if="[6, 20, 21, 22].includes(data.item.module_id)"
          >
            {{ data.item.name_type_client }}
          </b-badge>
        </span>
        <status-account :account="data.item" :text="true" />
        <div class="d-flex align-items-center">
          <span class="d-block">
            {{ data.item.client_account }}
          </span>
        </div>
      </template>
      <template #title="data">
        <div
          class="text-primary cursor-pointer font-weight-bolder"
          @click="openModalAddRound(data.item)"
        >
          {{ [1, 2].includes(data.item.type_round) ? "FIRST ROUND" : "-" }}
        </div>
      </template>
      <template #last_score_date="data">
        {{ data.item.last_score_date | myGlobalDay }}
      </template>
      <template #user_created="data">
        <b-col>
          <b-row
            ><span> {{ data.item.user_update }} </span></b-row
          >
          <b-row
            ><span>{{ data.item.updated_at | myGlobalWithHour }}</span></b-row
          >
        </b-col>
      </template>
      <template #userrecommendation="data">
        <b-col>
          <b-row>
            <span> {{ data.item.userrecommendation }} </span>
          </b-row>
          <b-row
            ><span>{{
              data.item.created_recommendation | myGlobalWithHour
            }}</span></b-row
          >
        </b-col>
      </template>
      <template #recommended_services="{ item }">
        <b-badge v-if="!displayedServices(item)" variant="light-danger">
          <div class="d-flex justify-content-center align-items-center gap-5">
            <feather-icon icon="SlashIcon" />
            <span class="lh-0"> None </span>
          </div>
        </b-badge>
        <b-badge v-else variant="light-info">
          <div class="d-flex justify-content-center align-items-center gap-5">
            <feather-icon icon="GridIcon" />
            <span class="lh-0">
              {{ displayedServices(item).length }} services
            </span>
          </div>
        </b-badge>
      </template>
      <template #read="data">
        <center>
          <b-badge
            pill
            :variant="data.item.read ? 'light-danger' : 'light-success'"
            :class="data.item.read ? 'text-danger' : 'text-success'"
          >
            {{ data.item.read | statusDisputeResultsRead }}
          </b-badge>
        </center>
      </template>
      <template #type="data">
        <b-badge
          class="w-100 text-center d-flex justify-content-center"
          :variant="data.item.type == 1 ? 'light-success' : 'light-warning'"
        >
          {{ data.item.type == 1 ? "LETTER" : "RECOVERY" }}
        </b-badge>
      </template>
      <template #request="{ item }">
        <center>
          <feather-icon
            class="text-primary cursor-pointer mr-1"
            v-b-tooltip.hover.right="'Completed Update Request'"
            @click="openActionModal(item)"
            size="20"
            icon="EyeIcon"
          />
          <tabler-icon
            icon="FoldersIcon"
            size="30"
            style="color: var(--warning)"
            class="cursor-pointer"
            @click="openModalRequest(item)"
          />
        </center>
      </template>
    </TableUpdateRequest>
    <modal-request v-if="activeModalRequest" @closeMe="closeModalRequest" />
  </div>
</template>
<script>
import { completedFields } from "@/views/commons/components/dispute-results/views/data/fields.data";
import DataFilters from "@/views/specialist-digital/views/updates/data/filters.data.js";
import TableUpdateRequest from "@/views/commons/components/dispute-results/views/components/TableUpdateRequest.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import UpdateService from "@/views/specialist-digital/views/updates/services/update.service.js";
import ModalRequest from "@/views/commons/components/dispute-results/views/components/ModalRequest.vue";
import DisputeResultsService from "@/views/commons/components/dispute-results/services/dispute-results.service";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "TableCompleted",
  components: {
    TableUpdateRequest,
    StatusAccount,
    ModalRequest,
  },
  data() {
    return {
      completedFields,
      DataFilters,
      activeModalRequest: false,
    };
  },
  computed: {
    ...mapGetters({
      gProgram: "DebtSolutionDisputeResult/gProgram",
      currentUser: "auth/currentUser",
    }),
    sendParams() {
      return {
        orderby: "created_recommendation",
        status: 2,
        completed_from: this.getFilterByKey("completed_from").model,
        completed_to: this.getFilterByKey("completed_to").model,
      };
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    accessElementTable() {
      return this.$refs.tableUpdateRequest;
    },
    isDigital() {
      return this.$route.meta.is_digital;
    },
  },
  methods: {
    ...mapMutations({
      M_SET_CLIENT: "DebtSolutionDisputeResult/M_SET_CLIENT",
      M_SET_MODAL_DATA: "DebtSolutionDisputeResult/M_SET_MODAL_DATA",
      M_CHANGE_STATUS_ORDER: "DebtSolutionDisputeResult/M_CHANGE_STATUS_ORDER",
    }),
    ...mapActions({
      fillDataTableModal: "DebtSolutionDisputeResult/fillDataTableModal",
      setEmailModal: "DebtSolutionDisputeResult/setEmailModal",
    }),
    async getSpecialist() {
      let result = [];
      const params = {
        module_id: 11,
        user_id: this.currentUser.user_id,
        role_id: this.currentUser.role_id,
      };
      result = await UpdateService.getUsersProgramsspNcr(params);
      this.users = result.data;
      var newList = this.users.map(
        (s) => ({ label: "All", value: 0 }, { label: s.user_name, value: s.id })
      );
      newList.splice(0, 0, { label: "All", value: 0 }); //add position 0
      this.getFilterByKey("specialist").options = newList;
    },
    async start() {
      await this.getSpecialist();
    },
    getFilterByKey(key) {
      return this.DataFilters.find((filter) => filter.key === `${key}`);
    },
    getRouterLink(client_account_id) {
      return this.accessElementTable.getRouterLink(client_account_id);
    },
    openModalAddRound(data) {
      this.accessElementTable.openModalAddRound(data);
    },
    displayedServices(item) {
      if (item.recommended_services) {
        const services = JSON.parse(item.recommended_services);
        return services;
      }
    },
    async openActionModal(item) {
      try {
        this.addPreloader();
        const { data } = await UpdateService.getCompletedUrInfo({
          id: item.id,
          score_id: item.score_id,
        });
        let dataUpdate = item;
        dataUpdate.rounds = data.rounds;
        dataUpdate.creditUtilized = data.creditUtilized;
        dataUpdate.deletedAccounts = data.deletedAccounts;
        dataUpdate.recommended_services = data.recommended_services;
        dataUpdate.agent = data.agent;
        this.removePreloader();
        this.accessElementTable.openActionModal({
          dataClient: dataUpdate,
          revision: false,
          section: "UPDATE",
        });
      } catch (ex) {
        this.removePreloader();
        console.log(ex);
      }
    },
    async getDisputeCounter() {
      const params = {
        read: 1,
        program_id: this.gProgram(this.moduleId).value,
        is_digital: this.isDigital,
      };
      const data = await DisputeResultsService.getNotificationsDispute(params);
      if (data.length > 0) {
        const payload = {
          routeName: counterRoute,
          tag: data[0].count,
        };
        this.$store.dispatch(
          "BoostCreditDisputeResult/A_SET_COUNTER_DISPUTE",
          data[0].count
        );
        this.$store.commit(
          "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
          payload
        );
      }
    },
    async openModalRequest(row) {
      this.addPreloader();
      this.M_SET_CLIENT({
        name: row.client_name,
        id: row.id,
        account: row.account,
        account_id: row.account_id,
        meEmail: row.id_credential,
      });
      this.M_SET_MODAL_DATA({
        request: row.type,
        observation: row.observation,
        recomendation: row.recommendation,
        userRecommendation: row.userrecommendation,
        dateRecommendation: row.created_recommendation,
        comments: [
          row.commentary1,
          row.commentary2,
          row.commentary3,
          row.commentary4,
          row.commentary5,
        ],
      });
      await this.fillDataTableModal({ id: row.id });
      if (row.type === 2)
        await this.setEmailModal({ client_id: row.account_id });
      if (row.read === 1) {
        await DisputeResultsService.updateReadDisputeResults({
          iddispute: row.id,
        });
        this.getDisputeCounter();

        this.accessElementTable.refreshTable();
      }

      this.activeModalRequest = true;
    },
    closeModalRequest() {
      this.activeModalRequest = false;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.start();
    });
  },
};
</script>
