<template>
  <b-container fluid class="p-0">
    <filter-slot
      class="border-3 border-table-radius p-0 m-0"
      :class="
        currentUser.modul_id == 5 ? 'border-top-info' : 'border-top-primary'
      "
      :filter="allfilters"
      :totalRows="paginate.totalRows"
      :paginate="paginate"
      :startPage="paginate.startPage"
      :toPage="paginate.toPage"
      :filterPrincipal="filterPrincipal"
      @reload="$refs['disputeResultsList'].refresh()"
      @onChangeCurrentPage="onChangeCurrentPage"
      @reset-all-filters="refreshTable"
    >
      <template #buttons>
        <slot name="buttons" />
      </template>
      <template #table>
        <b-table
          class="m-0 p-0 w-100"
          ref="disputeResultsList"
          :items="dataProvider"
          :fields="visibleFields"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
          :sort-direction="order"
          @sort-changed="changeStatusOrder"
          show-empty
          sticky-header="70vh"
          responsive
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template
            v-for="(fi, index) in fields"
            :slot="`cell(${fi.key})`"
            slot-scope="data"
          >
            <template v-if="fi.key === 'tracking'">
              <div :key="index">
                <feather-icon
                  class="text-info cursor-pointer"
                  v-b-tooltip.hover.right="'Tracking'"
                  @click="openUrModalTracking(data.item)"
                  size="20"
                  icon="ListIcon"
                />
              </div>
            </template>
            <slot :name="fi.key" v-bind="data">
              {{ data.value }}
            </slot>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <AddRoundModal
      v-if="modalAddRound.show"
      v-bind="addRoundModalScope.attr"
      v-on="addRoundModalScope.events"
    />
    <StepsCreditReport
      v-if="modalSteps.show"
      v-bind="modalStepCreditReportScope.attr"
      v-on="modalStepCreditReportScope.events"
    />
    <RequestUpdateTracking
      v-if="urModalTracking.show"
      :dataUr="urModalTracking.dataUr"
      @close="urModalTracking.show = false"
    />
    <ActionDoneReturnModal
      v-if="actionDoneReturnModal.show"
      v-bind="actionDoneReturnModalScope.attr"
      v-on="actionDoneReturnModalScope.events"
    />
  </b-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import disputeResultsService from "@/views/commons/components/dispute-results/services/dispute-results.service";
import AddRoundModal from "@/views/specialist-digital/views/clients/components/AddRoundModal.vue";
import StepsCreditReport from "@/views/commons/components/dispute-results/views/components/StepsCreditReport.vue";
import RequestUpdateTracking from "@/views/commons/components/dispute-results/views/components/modal/RequestUpdateTracking.vue";
import ActionDoneReturnModal from "@/views/commons/components/request-workplan/views/components/modal/ActionDoneReturnModal.vue";

export default {
  name: "TableUpdateRequest",
  props: {
    fields: {
      type: Array,
      required: true,
    },
    allfilters: {
      type: Array,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
    order: {
      type: String,
      default: () => "asc",
    },
  },
  components: {
    AddRoundModal,
    StepsCreditReport,
    RequestUpdateTracking,
    ActionDoneReturnModal,
  },
  data() {
    return {
      orderBy: "asc",
      actionDoneReturnModal: {
        show: false,
        dataClient: null,
        revision: null,
        section: null,
      },
      urModalTracking: {
        show: false,
        dataUr: null,
      },
      modalAddRound: {
        show: false,
        itemClient: null,
        validateModal: 3,
      },
      modalSteps: {
        show: false,
        rowData: null,
      },
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeHolder: "Client...",
        model: "",
      },
      isBusy: false,
      tabs: ["IN PROCESS", "COMPLETED", "IN DELETED", "TO REVISION"],
      paginate: {
        currentPage: 1,
        perPage: 25,
        totalRows: 0,
        toPage: 0,
        startPage: 0,
      },
      steps: {
        icons: ["UpdatedIcon", "DisputeIcon", "RecommendIcon", "CheckIcon"],
        step: ["UPDATE ITEMS", "DISPUTE", "RECOMMEND", "TO REVIEW"],
      },
    };
  },
  computed: {
    ...mapGetters({
      gProgram: "DebtSolutionDisputeResult/gProgram",
      currentUser: "auth/currentUser",
      getRefreshTable: "DebtSolutionDisputeResult/getRefreshTable",
    }),
    addRoundModalScope() {
      return {
        attr: {
          "data-round": this.modalAddRound.itemClient,
          "validate-modal": this.modalAddRound.validateModal,
          "show-all-letters": false,
        },
        events: {
          close: () => {
            this.modalAddRound.show = false;
            this.modalAddRound.itemClient = null;
          },
        },
      };
    },
    modalStepCreditReportScope() {
      return {
        attr: {
          rowData: this.modalSteps.rowData,
        },
        events: {
          close: () => {
            this.modalSteps.show = false;
          },
          reload: () => {
            this.refreshTable();
          },
        },
      };
    },
    actionDoneReturnModalScope() {
      const { show, ...attr } = this.actionDoneReturnModal;
      return {
        attr,
        events: {
          close: () => {
            this.actionDoneReturnModal.show = false;
            this.actionDoneReturnModal.dataClient = null;
            this.actionDoneReturnModal.revision = null;
            this.actionDoneReturnModal.section = null;
          },
          reload: this.refreshTable,
        },
      };
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isDigital() {
      return this.$route.meta.is_digital;
    },
    inTabDeleted() {
      let tab = this.$route.meta.tab;
      return this.tabs.find((x) => x === tab) && tab === "IN DELETED";
    },
    otherParams() {
      let otherParams = {};
      if (this.inTabDeleted) {
        otherParams.is_deleted = 1;
        otherParams.f_specialist = this.getFilterByKey("specialist").model;
      } else {
        otherParams.module_id = this.moduleId;
        otherParams.is_deleted = 0;
        otherParams.f_specialist = null;
      }
      return otherParams;
    },
    setParams() {
      return {
        advisorid: null,
        from: this.getFilterByKey("from").model,
        name_text: this.filterPrincipal.model,
        order: this.order,
        program: this.gProgram(this.moduleId).value,
        perPage: this.paginate.perPage,
        page: this.paginate.currentPage,
        roleid: this.currentUser.role_id,
        to: this.getFilterByKey("to").model,
        userid: this.currentUser.user_id,
        typee: this.getFilterByKey("status").model,
        is_digital: this.isDigital,
        ...this.otherParams,
        ...this.params,
      };
    },
    validCurrentUser() {
      return (
        this.currentUser.role_id === 1 ||
        this.currentUser.role_id === 17 ||
        this.currentUser.role_id === 2 ||
        this.currentUser.role_id === 14
      );
    },
    currentTab() {
      return this.$route.meta.tab;
    },
  },
  watch: {
    getRefreshTable(value) {
      if (value) {
        this.$refs["disputeResultsList"].refresh();
        this.CHANGE_REFRESH_TABLE(false);
      }
    },
  },
  created() {
    this.orderBy = this.order;
  },
  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: "DebtSolutionDisputeResult/CHANGE_REFRESH_TABLE",
    }),
    changeStatusOrder() {
      this.orderBy = this.orderBy === "asc" ? "desc" : "asc";
    },
    onChangeCurrentPage(e) {
      this.paginate.currentPage = e;
    },
    refreshTable() {
      this.$refs.disputeResultsList.refresh();
    },
    async dataProvider() {
      try {
        const { data, ...pagination } =
          await disputeResultsService.getDisputeResults(this.setParams);
        const { total, current_page, per_page, from, to } = pagination;
        this.paginate.totalRows = total;
        this.paginate.currentPage = current_page;
        this.paginate.perPage = per_page;
        this.paginate.startPage = from ?? 0;
        this.paginate.toPage = to ?? 0;
        return data;
      } catch (ex) {
        console.log("dataProvider", ex);
      }
    },
    getFilterByKey(key) {
      return this.allfilters.find((filter) => filter.key === `${key}`);
    },
    openModalAddRound(data) {
      console.log(data);
      this.modalAddRound.itemClient = {
        id: data.ncr_letters_id,
        status_round: data.status_round,
        round_created_at: data.round_created_at,
        idAccount: data.client_account_id,
        client_account: data.client_account,
        account: data.client_account,
        clientName: data.client_name,
        clientData: {
          dob: data.dob,
          address: data.address,
          ssn: data.ssn,
          itin: data.itin,
          other: data.other,
          origin_country: data.origin_country,
        },
        isDirect: {
          id: data.letter_id,
          title: data.title,
          format: data.format,
        },
      };
      this.modalAddRound.show = true;
    },
    openUrModalTracking(item) {
      this.urModalTracking.show = true;
      this.urModalTracking.dataUr = item;
    },
    async openModalSteps(item) {
      try {
        this.modalSteps.show = true;
        this.modalSteps.rowData = item;
      } catch (error) {
        console.error(error);
        this.removePreloader();
      } finally {
        this.removePreloader();
      }
    },
    getSteps() {
      return this.steps;
    },
    filterColumn(key, bool) {
      let field = this.fields.find((field) => field.key == `${key}`);
      if (field) {
        field.visible = bool;
      }
    },
    openActionModal(data) {
      this.actionDoneReturnModal = {
        show: true,
        ...data,
      };
    },
    getToParams(client_account_id) {
      let to;
      switch (this.moduleId) {
        case 5:
          to = "debt-solution-dashboard";
          break;
        case 7:
          to = "boost-credit-dashboard";
          break;
        case 6:
          to = "credit-experts-dashboard";
          break;
        case 20:
          to = "connection-clients-account";
          break;
        case 28:
          to = "specialists-client-dashboard";
          break;
        default:
          to = null;
          break;
      }
      return {
        name: to,
        params: {
          idClient: client_account_id,
        },
      };
    },
    getRouterLink(client_account_id) {
      return {
        validModuleId: this.moduleId == 22 && this.validCurrentUser,
        to: {
          name: "ce-customer-service-dashboard",
          params: {
            idClient: client_account_id,
            hasAccess: true, // is alert
            typeAccess: 6, // Welcome,
            wayAccess: 4, // enter by modal
          },
        },
      };
    },
    hideFilters() {
      let visible_completed_from = false;
      let visible_completed_to = false;
      switch (this.currentTab) {
        case "IN PROCESS":
          break;
        case "TO REVISION":
          break;
        case "COMPLETED":
          visible_completed_from = true;
          visible_completed_to = true;
          break;
        case "IN DELETED":
          visible_completed_from = true;
          visible_completed_to = true;
          break;
        default:
          break;
      }
      this.getFilterByKey("completed_from").visible = visible_completed_from;
      this.getFilterByKey("completed_to").visible = visible_completed_to;
    },
  },
  mounted() {
    this.hideFilters();
  },
};
</script>
